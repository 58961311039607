@import "../../../../../../node_modules/ag-grid-community/src/styles/ag-grid";
@import "../../../../../../node_modules/ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";

.ag-theme-custom-alpine {
  @include ag-theme-alpine(
    (
      borders: true,
      border-color: #eeeff5
    )
  );

  // 헤더 백그라운드
  .ag-header-row-column {
    background: #fafafc;
  }

  // 필터 백그라운드
  .ag-header-row-floating-filter {
    background: white;
  }
}


